<template>
  <template-table
    :column="column"
    :data="data"
    :tableAction="false"
    :selectAction="false"
    :searchAction="false"
    :pagingAction="false"
    @sortBy="sortBy"
  >
    <template v-slot:body="{ item }">
      <td>{{ item.code }}</td>
      <td>{{ item.name }}</td>
      <td class="text-center">{{ item.replayTime }}</td>
      <td>
        <action-dropdown
          :value="item"
          :show_copy="false"
          :show_delete="false"
          :show_view="false"
          :show_edit="false"
          boundary="window"
        >
          <b-dropdown-text
            tag="div"
            class="navi-item cursor-pointer"
            @click="editExercise(item)"
          >
            <a class="navi-link text-primary">
              <span class="menu-icon svg-icon svg-icon-sm">
                <inline-svg
                  class="svg-icon"
                  src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                />
              </span>
              <span class="navi-text text-primary"
                >Chỉnh sửa nhóm động tác</span
              >
            </a>
          </b-dropdown-text>
        </action-dropdown>
      </td>
    </template>
  </template-table>
</template>

<script>
export default {
  name: 'MovementRouteLessonDetailTable',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      column: [
        {
          key: 'code',
          label: 'Mã',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Tên nhóm động tác',
          sortable: true,
        },
        {
          key: 'loopNum',
          label: 'Số lần lặp lại',
          sortable: false,
          class: 'text-center',
        },
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    sortBy(params) {
      this.$emit('sortBy', params);
    },
    editExercise(params) {
      this.$emit('edit', params);
    },
  },
};
</script>

<style scoped></style>
